<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('create_classes')"
                        :is-filter="false"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('create_classes')"
                              :is-filter="false"
                />
            </template>
            <ValidationObserver ref="storeForm">
                <h5>{{ $t('file') }}</h5>
                <div class="mb-5">
                    <ValidationProvider name="file" rules="required" v-slot="{errors}">
                        <b-input-group class="mb-0">
                            <b-form-file v-model="form.file"
                                         :placeholder="$t('select_file')"
                                         :state="errors[0] ? false : null"
                            />
                            <b-input-group-append>
                                <b-button variant="outline-secondary"
                                          class="btn-40"
                                          :style="errors[0] ? 'border-left: 1px solid #ff9eae':''"
                                >
                                    <i class="ri-upload-2-line"></i>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </ValidationProvider>
                </div>
                <h5>{{ $t('class_settings') }}</h5>
                <div class="border rounded p-3 mb-5">
                    <b-row>
                        <b-col cols="12" md="6">
                            <ValidationProvider name="module" rules="required" v-slot="{errors}">
                                <b-form-group :label="$t('module')">
                                    <parameter-selectbox v-model="form.module"
                                                         :validate-error="errors[0]"
                                                         code="prep_modules"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="level" rules="required" v-slot="{errors}">
                                <b-form-group :label="$t('level')">
                                    <parameter-selectbox v-model="form.level"
                                                         code="prep_levels"
                                                         :validateError="errors[0]"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6">
                            <ValidationProvider name="number_of_students" rules="required" v-slot="{errors}">
                                <b-form-group :label="$t('number_of_students')">
                                    <b-form-input v-model="form.number_of_students"
                                                  type="number"
                                                  step="1"
                                                  min="0"
                                                  v-check-min-max-value
                                                  :state="errors[0] ? false : null"
                                    />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="order_method" rules="required" v-slot="{errors}">
                                <b-form-group :label="$t('order')">
                                    <b-form-select v-model="form.order_by"
                                                   :options="orderOptions"
                                                   :state="errors[0] ? false : null"
                                    />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex justify-content-center">
                    <processing-button variant="primary"
                                       :processing="formProcess"
                                       :label="$t('create')"
                                       @click="store"
                    />
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

    // Components
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import ProcessingButton from '@/components/elements/ProcessingButton';

    // Service
    import PrepStudentService from '@/services/PrepStudentService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            ParameterSelectbox,
            ProcessingButton,
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                form: {},
                formProcess: null,

                orderOptions: [
                    {value: null, text: '-'},
                    {value: 'student_number', text: this.$t('student_number')},
                    {value: 'name', text: this.$t('name')},
                    {value: 'surname', text: this.$t('surname')},
                    {value: 'random', text: this.$t('random')}
                ]
            }
        },
        methods: {
            async store() {
                const isValid = await this.$refs.storeForm.validate();
                if (isValid) {
                    const formData = new FormData();
                    formData.append('file', this.form.file);
                    formData.append('module', this.form.module);
                    formData.append('level', this.form.level);
                    formData.append('number_of_students', this.form.number_of_students);
                    formData.append('order_by', this.form.order_by);

                    this.formProcess = true;
                    PrepStudentService.createClassesFromExcel(formData)
                                      .then(response => {
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                      })
                                      .catch(e => {
                                          this.showErrors(e)
                                      })
                                      .finally(() => {
                                          this.formProcess = false;
                                      })
                }
            }
        }
    }
</script>
